import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import store from 'store';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Share, Envelope } from 'react-bootstrap-icons';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import { ResourceGrid } from '@components/ResourceGrid';
import ConfirmClear from '@components/bookmarks/ConfirmClear';
import { useMPEvent } from '@util/mixpanel';
import sessionStorage from '@util/sessionStorage';

import {
  buttonWrapper,
  dropdownButton,
  emptyContent,
  saveCheckbox,
  saveText,
} from '@css/modules/bookmarks.module.scss';

function createEmailTemplate(bookmarks, nodeLocale) {
  const ENCODED_NEW_LINE = '%0A';
  const ENCODED_SPACE = '%20';

  // FIXME: This should have Français!
  const subject = 'Checkout these helpful resources'.replace(
    /\s+/g,
    ENCODED_SPACE,
  );

  // Defaults to the prod URL at build time when `window` is not available
  const siteOrigin =
    typeof window === 'undefined'
      ? 'https://wellnesstogether.ca'
      : window.location.origin;
  const baseUrl = `${siteOrigin}/${nodeLocale}/`;

  let body = '';
  for (const bookmark of bookmarks) {
    const title = `- ${bookmark.title.trim()}: `;
    body += title + baseUrl + bookmark.slug + ENCODED_NEW_LINE;
  }

  return `?subject=${subject}&body=${body}`;
}

export default function Bookmarks({
  data: { contentfulBookmarksPage, contentfulHomePage, allContentfulResourcePage },
  pageContext,
}) {
  const {
    node_locale: nodeLocale,
    title,
    copy,
    saveTitle,
    saveCopy,
    empty,
    shareButton,
    emailOption,
    closeOption,
  } = contentfulBookmarksPage;
  const { selector } = contentfulHomePage;

  const [bookmarks, setBookmarks] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState(
    createEmailTemplate(bookmarks, nodeLocale),
  );
  const { trackPageLoad } = useMPEvent();

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/bookmarks`,
      url_name: 'bookmarks',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function updateBookmarks() {
      const bookmarkStorage = store.get('bookmarkStorage');
      const allBookmarks =
        bookmarkStorage === 'local'
          ? store.get('bookmarks')
          : sessionStorage.get('bookmarks');
      if (allBookmarks && allBookmarks.length) {
        const resources = allContentfulResourcePage.edges.map((r) => r.node);
        setBookmarks(
          resources.filter((resource) => allBookmarks.includes(resource.id)),
        );
      } else {
        setBookmarks([]);
      }
    }

    store.watch('bookmarks', updateBookmarks);
    store.watch('bookmarkStorage', updateBookmarks);
    sessionStorage.watch('bookmarks', updateBookmarks);
    updateBookmarks();
  }, [allContentfulResourcePage.edges]);

  useEffect(() => {
    setEmailTemplate(createEmailTemplate(bookmarks, nodeLocale));
  }, [bookmarks, nodeLocale]);

  function clearBookmarks() {
    store.set('bookmarks', []);
    sessionStorage.set('bookmarks', []);
    window.scroll({ top: -200, behavior: 'smooth' });
  }

  function toggleBookmarkStorage(event) {
    store.set(
      'bookmarkStorage',
      store.get('bookmarkStorage') === 'undefined' || event.target.checked
        ? 'local'
        : 'session',
    );
  }

  return (
    <PageLayout
      node_locale={nodeLocale}
      pageTitle={title}
      translations={pageContext.translations}
      page="bookmarks"
    >
      <PageHero data={{ header: title }} />

      {bookmarks.length ? (
        <Container className="my-4">
          <Row className="my-5">
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: copy.childMarkdownRemark.html,
                }}
              />
            </Col>
            <Col xs={12} className="mt-4">
              <Form.Check
                checked={store.get('bookmarkStorage') === 'local'}
                className={saveCheckbox}
                type="checkbox"
                id="saveForLater"
                label={saveTitle}
                onChange={toggleBookmarkStorage}
              />
              <div
                className={saveText}
                dangerouslySetInnerHTML={{
                  __html: saveCopy.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs={12} className={buttonWrapper}>
              <ConfirmClear
                node_locale={nodeLocale}
                clearBookmarks={clearBookmarks}
              />
              <Dropdown
                as={(props) => <Button {...props} variant="secondary" />}
                className={dropdownButton}
                id="share-dropdown-button"
              >
                <Dropdown.Toggle as="span">
                  <Share className="mr-2 mt-n1" /> {shareButton}
                </Dropdown.Toggle>
                <Dropdown.Menu align="right">
                  <Dropdown.Item href={`mailto:${emailTemplate}`}>
                    <Envelope className="mr-2 mt-n1" />
                    {emailOption}
                  </Dropdown.Item>
                  <Dropdown.Item aria-label="close">
                    {closeOption}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <hr />
          <ResourceGrid
            resources={bookmarks}
            pageName={pageContext.pageName}
            locale={nodeLocale}
            showMore={bookmarks.length}
            setShowMore={() => {}}
            selector={selector}
          />
        </Container>
      ) : (
        <Container className="my-4">
          <Row className="my-5">
            <Col xs={12} className={emptyContent}>
              <div
                dangerouslySetInnerHTML={{
                  __html: empty.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulHomePage(node_locale: { eq: $node_locale }) {
      selector {
        showMore
      }
    }
    contentfulBookmarksPage(node_locale: { eq: $node_locale }) {
      node_locale
      title
      copy {
        childMarkdownRemark {
          html
        }
      }
      saveTitle
      saveCopy {
        childMarkdownRemark {
          html
        }
      }
      empty {
        childMarkdownRemark {
          html
        }
      }
      clearButton
      shareButton
      emailOption
      linkOption
      closeOption
    }
    allContentfulResourcePage(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id: contentful_id
          title
          slug
          tags
          provider {
            name
            logo {
              title
              gatsbyImageData(
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            branding {
              background
              text
              tag
            }
          }
          tileImage {
            title
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
