import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Trash, X } from 'react-bootstrap-icons';

import locale from '@util/locale';

import { button, clear } from '@css/modules/bookmarks.module.scss';

export default function ConfirmClear({ node_locale, clearBookmarks }) {
  const { allContentfulBookmarksPage } = useStaticQuery(graphql`
    query ConfirmClearQuery {
      allContentfulBookmarksPage {
        edges {
          node {
            node_locale
            clearButton
            confirmClearTitle
            confirmClearCopy
          }
        }
      }
    }
  `);
  const { node } = allContentfulBookmarksPage.edges.find(
    (edge) => edge.node.node_locale === node_locale,
  );

  const [show, toggle] = useState(false);
  return (
    <>
      <Button
        onClick={() => toggle(true)}
        className={`${clear} ${button}`}
        variant="outline-secondary"
      >
        <Trash className="mr-2 mt-n1" />
        {node.clearButton}
      </Button>
      <Modal centered show={show} onHide={() => toggle(false)}>
        <Button
          variant="outline"
          aria-label="close"
          className="modalCloseButton"
          onClick={() => toggle(false)}
        >
          <X size={24} />
        </Button>
        <Modal.Body className="text-center p-5 position-relative">
          <h4 className="text-secondary">{node.confirmClearTitle}</h4>
          <p className="my-4">{node.confirmClearCopy}</p>
          <div className="d-flex justify-content-center">
            <Button
              className="px-5 mr-2"
              variant="primary"
              onClick={clearBookmarks}
            >
              {locale[node_locale].yes}
            </Button>
            <Button
              className="px-5"
              variant="outline-primary"
              onClick={() => toggle(false)}
            >
              {locale[node_locale].no}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
